@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  background-color: #eeeff2;
}

.appContainer > footer {
  position: sticky;
  top: 100vh;
}
/* Width */
::-webkit-scrollbar {
  height: 5px;
  width: 10px;
  border-radius: 50px;

  appearance: none;
}

/* Track */
::-webkit-scrollbar-track {
  //background-color: transparent;
  //background: #e238e8;
  background: transparent;

  border-radius: 100px;
  -webkit-box-shadow: none;
  appearance: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-right: 10px;
  background: #555;
  border-radius: 100px;
  background-clip: padding-box;
  padding: 0 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
